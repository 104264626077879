<template>
    <div class="baseMain nui-scroll">
        <div class="frontPage">
            <div class="frontPageIn">
                <div class="frontPageTop">
                    <span class="frontPageTopText">数据汇总</span>
                </div>
                <div class="frontPageTop">
                    <span class="frontPageTopText2">数据计算可能略有延时，最晚于次日10时更新前一天数据</span>
                </div>
                <div class="baseSearch clearfloat" style="margin-top: 10px;">
                    <div class="baseSearchOne">
                        <div class="baseSearchOneLeft">
                            <span>时间：</span>
                        </div>
                        <div class="baseSearchOneRight2 clearfloat">
                            <!--<div style="height: 100%;float: left;font-size: 0;">-->
                            <!--<el-radio-group v-model="searchForm.radio" size="mini">-->
                            <!--<el-radio-button label="1">今日</el-radio-button>-->
                            <!--<el-radio-button label="2">昨日</el-radio-button>-->
                            <!--<el-radio-button label="3">近七日</el-radio-button>-->
                            <!--</el-radio-group>-->
                            <!--</div>-->
                            <div style="height: 100%;float: left;font-size: 0;margin-left: 10px;">
                                <el-date-picker v-model="searchForm.time" type="daterange" unlink-panels
                                                range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                                                value-format="YYYY-MM-DD" :shortcuts="shortcuts"></el-date-picker>
                                <!--<el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="-"-->
                                <!--start-placeholder="开始时间" end-placeholder="结束时间"-->
                                <!--value-format="YYYY-MM-DD HH:mm:ss" clearable-->
                                <!--:shortcuts="shortcuts"></el-date-picker>-->
                            </div>
                        </div>
                    </div>
                    <div class="baseSearchOne">
                        <el-button type="primary" style="margin-left: 20px;" @click="search">搜索</el-button>
                        <el-button @click="cleanAll" style="margin-left: 20px;">清空</el-button>
                    </div>
                </div>
                <div class="baseOperate">
                    <el-button type="primary" @click="openExport" style="float: right;">下载报表</el-button>
                </div>
                <div class="frontPageEchart" v-loading="tableLoading">
                    <div class="frontPageEchartMain">
                        <publicEchart v-if="publicEchartShow" :chartName="'left'" :xAxis="xAxisLeft"
                                      :series="seriesLeft"></publicEchart>
                    </div>
                    <div class="frontPageEchartMain">
                        <publicEchart v-if="publicEchartShow" :chartName="'right'" :xAxis="xAxisRight"
                                      :series="seriesRight"></publicEchart>
                    </div>
                </div>
                <div class="frontPageTitle">
                    <div class="frontPageTitleLine"></div>
                    <span>详细数据</span>
                </div>
                <div class="frontPageTable" style="margin-top: 10px;" v-loading="tableLoading">
                    <publicTable :publicTableOtherHeight="searchHeight" :publicTableData="tableData">
                        <el-table-column prop="time" label="时间" width="110"></el-table-column>
                        <el-table-column prop="wechat_count" label="微信收款总笔数"></el-table-column>
                        <el-table-column prop="wechat_price_count" label="微信收款总额"></el-table-column>
                        <!--<el-table-column prop="name" label="微信退款总笔数"></el-table-column>-->
                        <!--<el-table-column prop="name" label="微信退款总额"></el-table-column>-->
                        <el-table-column prop="cash_count" label="现金收款总笔数"></el-table-column>
                        <el-table-column prop="cash_price_count" label="现金收款总额"></el-table-column>
                        <el-table-column prop="refund_count" label="现金退款总笔数"></el-table-column>
                        <el-table-column prop="refund_price_count" label="现金退款总额"></el-table-column>
                        <el-table-column prop="profit" label="实际入账"></el-table-column>
                    </publicTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import publicEchart from '../../components/publicEchart.vue'

import publicTable from '../../components/publicTable.vue'

import XLSX from 'xlsx';

import {businessGet} from '../../api/business.js'

import {utilsExportExcel} from '../../utils/utils.js'

export default {
    name: "paySummary",
    components: {
        publicEchart,
        publicTable
    },
    data() {
        return {
            shortcuts: [
                {
                    text: '昨日',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 1)
                        return [start, end]
                    },
                },
                {
                    text: '最近一周',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]
                    },
                },
                {
                    text: '最近一个月',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        return [start, end]
                    },
                },
            ],
            searchForm: {
                time: []
            },
            tableLoading: false,
            publicEchartShow: false,
            xAxisLeft: [],
            seriesLeft: [],
            xAxisRight: [],
            seriesRight: [],
            searchHeight: 0,
            tableData: [],
        }
    },
    created() {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        var moment = require('moment');
        this.searchForm.time = [moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD')];
        this.search();
    },
    methods: {
        openExport() {
            let url = '/admin/v1/summary?is_export=1';
            if (this.searchForm.time[0] && this.searchForm.time[1]) {
                url = url + '&time=' + this.searchForm.time[0] + ' 00:00:00 - ' + this.searchForm.time[1] + ' 23:59:59';
            }
            utilsExportExcel(url)
        },
        exportExcel() {
            //数据表格
            var aoa = [
                ['表格在此', '', '', ''],
                ['', '', '', ''],
                ['姓名', '性别', '年龄', '注册时间'],
                ['张三', '男', 18, new Date()],
                ['李四', '女', 22, new Date()]
            ];

            //创建book
            var wb = XLSX.utils.book_new();

            var ws = XLSX.utils.aoa_to_sheet(aoa);
            //设置列宽
            ws['!cols'] = [
                {width: 50},
                {width: 15},
                {width: 15},
                {width: 10}
            ];
            ws['!merges'] = [
                // 设置A1-C1的单元格合并
                {
                    s: {r: 0, c: 0},
                    e: {r: 1, c: 3}
                }
            ];

            ws['A3'].l = {Target: "http://sheetjs.com", Tooltip: "Find us @ SheetJS.com!"};

            var aoa2 = [
                ['姓名', '性别', '年龄', '注册时间'],
                ['张三', '男', 18, new Date()],
                ['李四', '女', 22, new Date()]
            ];

            var ws2 = XLSX.utils.aoa_to_sheet(aoa2);

            ws2["A1"].s = {
                font: {sz: 14, bold: true, vertAlign: true},
                alignment: {vertical: "center", horizontal: "center"},
                fill: {bgColor: {rgb: "E8E8E8"}, fgColor: {rgb: "E8E8E8"}}
            };

            var aoa3 = [
                ['姓名', '性别', '年龄', '注册时间'],
                ['张三', '男', 18, new Date()],
                ['李四', '女', 22, new Date()]
            ];

            var ws3 = XLSX.utils.aoa_to_sheet(aoa3);
            ws3["!margins"] = {left: 0.25, right: 0.25, top: 0.75, bottom: 0.75, header: 0.3, footer: 0.3}

            //sheet写入book
            XLSX.utils.book_append_sheet(wb, ws, "操作合并，列宽");
            XLSX.utils.book_append_sheet(wb, ws2, "正常");
            XLSX.utils.book_append_sheet(wb, ws3, "操作间隔");

            //输出

            var timestamp = (new Date()).getTime();
            XLSX.writeFile(wb, "file" + timestamp + ".csv");
        },
        cleanAll() {
            this.searchForm.time = [];
            this.search();
        },
        search() {
            this.getTableData();
        },
        getTableData() {
            this.publicEchartShow = false;
            let url = '/admin/v1/summary';
            if (this.searchForm.time[0] && this.searchForm.time[1]) {
                url = url + '?time=' + this.searchForm.time[0] + ' 00:00:00 - ' + this.searchForm.time[1] + ' 23:59:59';
            }
            this.tableLoading = true;
            businessGet(url).then(res => {
                this.tableLoading = false;
                if (res.data.status == 200) {
                    let tableData = []
                    let list = res.data.data.list;
                    let range_time = res.data.data.range_time;
                    let cash_count = [];
                    let cash_price_count = [];
                    let refund_count = [];
                    let refund_price_count = [];
                    let wechat_count = [];
                    let wechat_price_count = [];
                    list.forEach((item, index) => {
                        let one = JSON.parse(JSON.stringify(item));
                        one.time = range_time[index]
                        tableData.push(one);
                        cash_count.push(item.cash_count * 1)
                        cash_price_count.push(item.cash_price_count * 1)
                        refund_count.push(item.refund_count * 1)
                        refund_price_count.push(item.refund_price_count * 1)
                        wechat_count.push(item.wechat_count * 1)
                        wechat_price_count.push(item.wechat_price_count * 1)
                    })
                    this.tableData = tableData;
                    this.xAxisLeft = range_time;
                    this.xAxisRight = range_time;
                    this.seriesLeft = [
                        {
                            name: '微信支付金额',
                            data: wechat_price_count,
                            type: 'line'
                        },
                        {
                            name: '现金支付金额',
                            data: cash_price_count,
                            type: 'line'
                        },
                        {
                            name: '现金退款金额',
                            data: refund_price_count,
                            type: 'line'
                        },
                    ];
                    this.seriesRight = [
                        {
                            name: '微信支付总笔数',
                            data: wechat_count,
                            type: 'line'
                        },
                        {
                            name: '现金支付总笔数',
                            data: cash_count,
                            type: 'line'
                        },
                        {
                            name: '现金退款总笔数',
                            data: refund_count,
                            type: 'line'
                        },
                    ];
                    this.publicEchartShow = true;
                }
                else {
                    this.$message({
                        showClose: true,
                        message: res.data.message,
                        type: 'warning'
                    });
                }
            })
        },
    }
}
</script>

<style scoped>

@import "../../assets/css/base.css";

.frontPage {
    width: 100%;
    box-sizing: border-box;
    padding-top: 24px;
    padding-left: 30px;
    padding-Right: 30px;
}

.frontPageIn {
    width: 100%;
    box-sizing: border-box;
}

.frontPageTop {
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    line-height: 32px;
}

.frontPageTopText {
    color: rgba(65, 148, 242, 1);
    font-size: 20px;
}

.frontPageTopText2 {
    color: rgba(112, 112, 112, 1);
    font-size: 12px;
}

.frontPageEchart {
    width: 100%;
    height: 400px;
    box-sizing: border-box;
    border: 1px solid #e7eaf0;
}

.frontPageEchartMain {
    width: 50%;
    height: 100%;
    float: left;
}

.frontPageTitle {
    width: 100%;
    height: 32px;
    box-sizing: border-box;
    padding-left: 10px;
    position: relative;
    color: rgba(112, 112, 112, 1);
    font-size: 16px;
    line-height: 32px;
    margin-top: 20px;
}

.frontPageTitleLine {
    width: 4px;
    height: 18px;
    background-color: rgba(65, 148, 242, 1);
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

</style>
